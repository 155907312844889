@font-face {
    font-family: emoji;
    font-weight: bold; /* Blink on Windows seems to require this */
    src: local('Apple Color Emoji'),
         local('Noto Color Emoji'),
         /* local('Segoe UI Emoji'), Microsoft don't support country flags */
         local('Twemoji Mozilla'),
         url(../fonts/twemoji.woff2);
    unicode-range: U+2300-23FF,   /* Miscellaneous Technical               */
                   U+2600-2653,   /* Miscellaneous Symbols                 */
                /* U+2654-265F,      Exclude Chess Pieces for FEN          */
                   U+2660-26FF,   /* Miscellaneous Symbols                 */
                   U+2700-27BF,   /* Dingbats                              */
                   U+2B00-2BFF,   /* Miscellaneous Symbols and Arrows      */
                   U+1F0CF,       /* Joker                                 */
                   U+1F1E6-1F1FF, /* Regional Indicator Symbols            */
                   U+1F300-1F5FF, /* Miscellaneous Symbols and Pictographs */
                   U+1F600-1F64F, /* Emoticons                             */
                   U+1F680-1F6FF, /* Transport and Map Symbols             */
                   U+1F900-1F9FF, /* Supplemental Symbols and Pictographs  */
                   U+1FA70-1FAFF; /* Symbols and Pictographs Extended-A    */
}

/* Ensure FEN has consistent aligned display. See #645 for details */
@font-face {
    font-family: chess;
    src: url(../fonts/chess.woff2);
    unicode-range: U+20, U+2654-265F; /* Space & Chess Pieces */
}

/* Inverted version of the font for dark mode. */
@font-face {
    font-family: chess-dark;
    src: url(../fonts/chess-dark.woff2);
    unicode-range: U+20, U+2654-265F; /* Space & Chess Pieces */
}

/* Ensure Mahjong doesn't have tofu */
@font-face {
    font-family: mahjong;
    size-adjust: 125%;
    src: url(../fonts/noto-sans-symbols-2-mahjong-subset.woff2);
    unicode-range: U+1F000-1F02F;
}

@font-face {
    font-family: 'Source Code Pro';
    src: url(../fonts/source-code-pro.woff2);
}
